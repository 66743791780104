import React from 'react';
import { connect } from 'react-redux'
import { commonType, sessionType } from './initialStoreValues';

import {Helmet} from "react-helmet";

import { startSession } from './actions'
import CustomHtmlParser from './components/CustomHtmlParser'
import Wizard from './components/Wizard'

import {sessionStates} from "./constants/states"

//import $ from 'jquery';

const LoadingScreen = (props) =>
<>
    <div className={props.fadeEffect === true? "overlay" : "overlay fadeOut"}></div>
    <div className={props.fadeEffect === true? "d-flex justify-content-center" : "d-flex justify-content-center fadeOut"}>
        <div className={props.fadeEffect === true? "spinner-border overlay-large-spinner text-light" : "spinner-border large-spinner text-light fadeOut"} role="status"></div>
    </div>
</>

const Screen404 = (props) => 
<div id="screen404Content" className="card col-11 col-md-8 mx-auto mt-4 mb-5">
    <div className="card-body justify-content-center">
        <h1>404 Page Not Found</h1>
    </div>
</div>

const ScreenCriticalError = (props) => 
<div id="screenCriticalErrorContent" className="card col-11 col-md-8 mx-auto mt-4 mb-5">
    <div className="card-body justify-content-center">
        <h1>Something went wrong</h1>
        <p>{props.message}</p>
    </div>
</div>


class App extends React.Component {

    componentDidMount() {
        let requestedpath = window.location.pathname
        this.props.dispatch(startSession(requestedpath, this.props.common.whitelabel));
    }
   
    render() {
        this.theme = this.props.common.theme
        //console.log(this.theme)
        //console.log($);
        //console.log("THISISATEST");
        let session = this.props.session        
        let header = <CustomHtmlParser data={this.theme.header}/>
        let info = "";// wl illion and all other hardcoded wls do not have info section, so default info to '';
        if(this.theme.info){
            info=<CustomHtmlParser data={this.theme.info}/>;
        }
        let footer = <CustomHtmlParser data={this.theme.footer}/>

        if(this.theme.favicon){
            document.getElementById("favicon").href = this.theme.favicon
        }

        return (
        <>
        <Helmet>
            {this.props.common.whitelabel === "" ? null : <link rel="stylesheet" type="text/css" href={"/whitelabel/" + this.props.common.whitelabel + "/wl.css"} />}
            <style type="text/css">{this.props.common.style}</style>

        </Helmet>
        <div className="d-flex flex-fill flex-column">
            
            {session.state === sessionStates.SESSION_STARTED && this.props.common.themeReady ? <>{header}<div className="mainWrapper"><Wizard /></div>{info}{footer}</>: null}

            {session.state === sessionStates.SESSION_NOT_INIT && !this.props.common.themeReady ? <LoadingScreen fadeEffect={true} /> : <LoadingScreen fadeEffect={false} /> }

            {session.state === sessionStates.SESSION_START_ERROR ? <>{header}<Screen404 />{footer}</> : null}

            {session.state === sessionStates.CRITICAL_ERROR ? <>{header}<ScreenCriticalError message={session.error.message} />{footer}</> : null}

        </div>        
        </>
        
        );
    }
}

App.propTypes = {
    common: commonType.isRequired,
    session: sessionType.isRequired
}

const mapStateToProps = (state) => {
    return {
        common : state.common,
        session: state.session
    }
}

export default connect(mapStateToProps)(App);