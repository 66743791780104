import {loginStates} from '../constants/states'
import actions from '../constants/actions'

const currentLogin = (state = [], action) => {
    switch (action.type) {

        //////////////////////////////////////////////////////////////////////////////////////////////
        //Insitution selection
        case actions.PRELOAD_INSTITUTION_START: //User selected institution with preload. API call will start, switch to spinner
            return {
                ...state,
                state: loginStates.LOGIN_STATE_LOADING,
                slug: action.slug
            }            

        case actions.PRELOAD_INSTITUTION_SUCCESS: //Fired after successfully performing /preload. Switching to login state
            return {
                state: loginStates.LOGIN_STATE_CREDENTIAL_FORM,
                slug: action.data.slug,
                mfa : null,
                error: {message: ''}
            }

        case actions.INSTITUTION_SELECTED: //Here we basically initialize/clear "login" part of global store. Switching to login state
            return {
                state: loginStates.LOGIN_STATE_CREDENTIAL_FORM,
                slug: action.slug,
                mfa : null,
                error: {message: ''}
            }

        case actions.CHECK_IF_SUBMIT_WINZ: //go to the page before winz login form.asking user if they have winz;
            return {
                state: loginStates.LOGIN_STATE_CHECK_IF_SUBMIT_WINZ,
                slug: '',
                mfa : null,
                error: {message: ''}
            }
        

        //////////////////////////////////////////////////////////////////////////////////////////////
        //Login-related
        case actions.LOGIN_SUBMIT_START:
            return {
                ...state,
                state: loginStates.LOGIN_STATE_LOADING
            }

        
            
        case actions.MFA_SUBMIT_START:
            return {
                ...state,
                state: loginStates.LOGIN_STATE_LOADING
            }



        case actions.ACCOUNTSELECTION_SUBMIT_START:
            return {
                ...state,
                state: loginStates.LOGIN_STATE_LOADING
            }



        //////////////////////////////////////////////////////////////////////////////////////////////
        //Session finalization
        case actions.SUBMIT_ALL_START:
            return {
                ...state,                                  
                state: loginStates.LOGIN_STATE_SUBMIT_ALL_LOADING    
            }

        case actions.SUBMIT_ALL_SUCCESS:
            return {
                ...state,                                  
                state: loginStates.LOGIN_STATE_ALL_FINISHED
            }            


        //////////////////////////////////////////////////////////////////////////////////////////////
        //Helpers
        case actions.LOGIN_PROCESS_LOADING: //Showing spinner while waiting on response from portal
            return {
                ...state,
                state: loginStates.LOGIN_STATE_LOADING,
                slug: action.slug
            }

        case actions.INSTITUTION_ERROR: //Showing error screen for current institution login process (not all-session-critical error)
            return {
                ...state,
                state: loginStates.LOGIN_STATE_ERROR,
                error: {message: action.message}
            }


      
        
        
        case actions.LOGIN_PROCESS_CANCELLED: //User decided to go back to institution selection
            return {
                state: loginStates.LOGIN_STATE_INSTITUTIONS_PICKER,
                slug: '',
                mfa : null,
                error: {message: ''}
            } 
            
        case actions.ADD_ANOTHER_INSTITUTION: //User decided to add another institution
            return {
                state: loginStates.LOGIN_STATE_INSTITUTIONS_PICKER,
                slug: '',
                mfa : null,
                error: {message: ''}
            }

        case actions.LOGIN_PROCESS_FINISHED:
            return {
                ...state,
                state: loginStates.LOGIN_STATE_FINISHED
            }        
        
        
        
        
        //TODO        

          
        case actions.LOGIN_PROCESS_MFA:
            return {
                ...state,
                state: loginStates.LOGIN_STATE_MFA_FORM,
                mfa: action.data.mfa,
                slug: action.data.slug
            }
        case actions.LOGIN_PROCESS_ACCOUNTS_SELECTION:
            return {
                ...state,
                state: loginStates.LOGIN_STATE_ACCOUNTS_SELECTION,
                accounts: action.data.accounts,
                slug: action.data.slug
            }

        

        default:
            return state
    }
}

export default currentLogin
