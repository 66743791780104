import React from 'react';

const ThemedSpinner = (props) => 
    <div className="form-group text-center">
        <div id="loginLoadingSpinner" className={props.theme.classes.loginLoadingSpinner} role="status">
            <span className="sr-only spinner-titile">{props.theme.text.loginLoadingText}</span>
        </div>
    </div> 

export default ThemedSpinner
