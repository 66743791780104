import { connect } from 'react-redux'
import React from 'react';

class Redirect extends React.Component {
	getRedirectUrl() {
		if (this.props.session.redirectUrl){
			let redirectUrl
			redirectUrl = this.props.session.redirectUrl.replace("postMessage:", "");
			window.location.href = redirectUrl
		}
		
	}

	componentDidMount() {
        setTimeout(() => {
           this.getRedirectUrl()
        }, 5000)
    }

	render() {
        return (
        	<div className="selector_REDIRECT_FORM form-group">
        		<h2 className="d-flex justify-content-center">You will be redirected in a few seconds. If you are not redirected, please press continue below.</h2>
         		<button className="form-control btn btn-primary" onClick={this.getRedirectUrl()}>Continue</button>
    		</div>
    		)
    }
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
        sessionData: state.sessionData
    }
}
   
export default connect(mapStateToProps)(Redirect)