import actions from '../constants/actions'

const finishedLogins = (state = [], action) => {
    switch (action.type) {
        case actions.LOGIN_PROCESS_FINISHED:
            return {
                list: [...state.list, action.data]
            }            
        default:
            return state
    }
}

export default finishedLogins