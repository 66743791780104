import React from 'react';

const DateOfBirthFields = ({ dobFields, onInputFieldChange }) => {
    const handleKeyDown = (event) => {
        const allowedKeys = ['Backspace', 'Tab'];
        if (!allowedKeys.includes(event.key) && !/^[0-9]+$/.test(event.key)) {
            event.preventDefault();
        }
    };
    
    return (
    <div className="form-group">
        <label>Date of Birth</label>
        <div className="horizontal-date-input-container" style={horizontalDateInputContainerStyle}>
            {dobFields.map(credentialElement => (
                <div key={credentialElement.fieldID} className="horizontal-date-input" style={horizontalDateInputStyle(credentialElement.fieldID)}>
                    <label className="dob-label">{credentialElement.name}</label>
                    <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        minLength={credentialElement.validation.minLength}
                        maxLength={credentialElement.validation.maxLength}
                        placeholder={credentialElement.description}
                        className="form-control"
                        onChange={onInputFieldChange(credentialElement.fieldID)}
                        onKeyDown={handleKeyDown}
                        required={!credentialElement.optional}
                        style={inputStyle}
                    />
                </div>
            ))}
        </div>
    </div>
);

};

const horizontalDateInputContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
};

const horizontalDateInputStyle = fieldID => ({
    marginRight: '10px', // spacing between date components
    width: fieldWidths[fieldID] || '20%', // specific width based on fieldID, defaulting to 20%
});

const inputStyle = {
    width: '100%',
};


const fieldWidths = {
    // 20% , 30%, 50% if need to fill the area.
    day: '20%',   
    month: '20%',  
    year: '40%',
};

export default DateOfBirthFields;
