import React from 'react';
import parse from 'html-react-parser'

const CustomHtmlParser  = (props) => {
    
    if (Array.isArray(props.data)) {
        return props.data.map((element, index) => { 
            return <CustomHtmlParser key={index} data={element}/> 
        });
    }

    //It can done with React.createElement but we want more restriction/control/security

    //It(TagName) needs to be capitalised
    const TagName = props.data.tagName.toLowerCase();
    const tagId = props.data.tagId ? props.data.tagId.toLowerCase() : "";
    const className = props.data.className ? props.data.className.toLowerCase() : "";

    if (TagName === 'text') {
        let text = replaceMarkup(props.data.text);
        return <>{parse(text)}</>;
    }
    
    const childernTags = props.data.children ? <CustomHtmlParser data={props.data.children}/> : null;

    const singleTags = [
        'img',
        'br',
        'hr',
        'link'
    ];

    let attrs = {}

    if (props.data.src) {
        attrs.src = props.data.src;
    }

    if (props.data.alt) {
        attrs.alt = props.data.alt;
    }

    if (props.data.href) {
        attrs.href = props.data.href;
    }

    if (props.data.width) {
        attrs.width = props.data.width;
    }

    if (props.data.height) {
        attrs.height = props.data.height;
    }

    if (props.data.type) {
        attrs.type = props.data.type;
    }

    if (props.data.rel) {
        attrs.rel = props.data.rel;
    }

    if (props.data.target) {
        attrs.target = props.data.target;
    }

    if (tagId !== "") {
        attrs.id = tagId
    }

    if (className !== "") {
        attrs.className = className
    }

    const tag = singleTags.indexOf() !== -1 ? <><TagName {...attrs}/></> : <><TagName {...attrs}>{childernTags}</TagName></>;

    return tag;           
}

//function to replace markup with html link in whitelabel data
export function replaceMarkup(value) {
    let regex = /\[(?!.*\[).*\]\(.*\)/g;
    let matches = value.match(regex);
    while(matches != null && matches.length > 0) {
        for(let key in matches) {
            //find text and link in match [text](link)
            let match = matches[key]
            let text = match.substring(
                match.lastIndexOf("[") + 1, 
                match.lastIndexOf("](")
            );
            let link = match.substring(
                match.lastIndexOf("](") + 2, 
                match.lastIndexOf(")")
            );
            value = value.replace(match,'<a target="_blank" href ="' + link + '">' + text + '</a>')
        }
        matches = value.match(regex);
    }
    return value;
}

export default CustomHtmlParser
