import React from 'react';
import { connect } from 'react-redux'

import FinishedInstitutionLogins from './FinishedInstitutionLogins'
import CurrentInstitutionLogin from './CurrentInstitutionLogin'
import {loginStates} from '../constants/states'
import CustomHtmlParser from './CustomHtmlParser'
import ThemedSpinner from './ThemedSpinner'
import CardWrapper from './CardWrapper'
import TermsAndConditions from './TermsAndConditions'
import Redirect from './Redirect'
import CheckIfSubmitWinz from "./CheckIfSubmitWinz";

import { themeType, currentLoginType, institutionType, sessionDataType } from '../initialStoreValues';

class Wizard extends React.Component {

    render() {
        //On first load show T&C "I agree" screen
        if (!this.props.sessionData.acceptedTerms) {

            return <CardWrapper classSelector="selector_TermsAndConditions">
                <TermsAndConditions 
                    theme={this.props.theme} 
                    dispatch={this.props.dispatch} 
                    settings={this.props.sessionData.clientSettings}
                    institutions={this.props.institutions} />
                </CardWrapper>
        }

        //After accepting T&Cs show institution selector
        const classSelector = "selector_" + this.props.currentLogin.state;

        switch (this.props.currentLogin.state) {
            case loginStates.LOGIN_STATE_CHECK_IF_SUBMIT_WINZ:
                return <CardWrapper classSelector={classSelector}>
                    <CheckIfSubmitWinz
                        dispatch={this.props.dispatch}
                        clientName={this.props.sessionData.clientSettings.name}
                        winzName={(this.props.institutions && this.props.institutions['winz'] && this.props.institutions['winz']['name'])?this.props.institutions['winz']['name']:'Work and Income NZ'}
                    />
                </CardWrapper>
            case loginStates.LOGIN_STATE_LOADING:         
                return <CardWrapper classSelector={classSelector}>                         
                        <div className="form-group">
                            <h2 className="d-flex justify-content-center spinner-titile">{this.props.institution.name}</h2>
                        </div>                                    
                        <ThemedSpinner theme={this.props.theme} />
                    </CardWrapper>            
            case loginStates.LOGIN_STATE_FINISHED:
                return <CardWrapper classSelector={classSelector}>
                        <FinishedInstitutionLogins withPicker={false} />
                    </CardWrapper>
            case loginStates.LOGIN_STATE_INSTITUTIONS_PICKER:
                return <CardWrapper classSelector={classSelector}>
                        <FinishedInstitutionLogins withPicker={true} />
                    </CardWrapper>
            case loginStates.LOGIN_STATE_SUBMIT_ALL_LOADING:
                return <CardWrapper classSelector={classSelector}>
                        <div className="form-group">
                            <h2 className="d-flex justify-content-center spinner-titile">{this.props.theme.text.submitAllLoadingText}</h2>
                        </div>
                        <ThemedSpinner theme={this.props.theme} />
                    </CardWrapper>
            case loginStates.LOGIN_STATE_ALL_FINISHED:
                if(this.props.session.redirectUrl){
                    return <CardWrapper>
                        <Redirect />                                   
                    </CardWrapper>
                }else{
                    return <CustomHtmlParser data={this.props.theme.completedScreen} />
                }
            default:
                return <CardWrapper classSelector={classSelector}>
                    {this.props.institution ? <CurrentInstitutionLogin /> : <ThemedSpinner theme={this.props.theme} />}
                    </CardWrapper>
        }      
    }
}

Wizard.propTypes = {
    theme : themeType,
    currentLogin: currentLoginType,
    institution: institutionType,
    sessionData: sessionDataType
}

const mapStateToProps = (state) => {
    return {
        theme : state.common.theme,
        session: state.session,
        currentLogin: state.currentLogin,
        institution: state.institutions.byslug[state.currentLogin.slug],
        sessionData: state.sessionData,
        institutions:state.institutions.byslug
    }
}

export default connect(mapStateToProps)(Wizard);
