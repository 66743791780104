import { sendLogEntry } from '../actions'

const loggerMiddleware = ({ getState }) => {
    return next => async action => {
 
        // Call the next dispatch method in the middleware chain.
        const returnValue = next(action)

        let { session } = getState();
        if (session.loggingEnabled && session.key !== '' && session.referralCode !== '') {
            //Remove some values from log entry: credentials, maybe institution list or whitelabel data
            let {credentials, ...logEntry} = action;
            sendLogEntry({referralCode: session.referralCode, sessionKey: session.key, timestamp: Date.now(), data: logEntry});
        }
  
        return returnValue
    }
}

export default loggerMiddleware

