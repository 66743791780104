import {sessionStates} from "../constants/states"

const session = (state = [], action) => {
    switch (action.type) {
        case 'SESSION_START_NO_CODE_PROVIDED':
            return {
                ...state,
                state: sessionStates.SESSION_START_ERROR,
                error: {code: 'NO_CODE', message: 'No reference code provided'}
            }
        case 'SESSION_START_SUCCESS':         
            return {
                ...state,
                state: sessionStates.SESSION_STARTED,
                sessionToken: action.sessionToken,
                referralCode: action.referralCode,
                postMessagesEnabled: action.hasOwnProperty('postMessagesEnabled') ? action.postMessagesEnabled : false,
                redirectUrl: action.hasOwnProperty('redirectUrl') ? action.redirectUrl : '',
                postMessageHost: action.hasOwnProperty('postMessageHost') ? action.postMessageHost : '*', 
                loggingEnabled: action.hasOwnProperty('loggingEnabled') ? action.loggingEnabled : false, 
                error: {code: '', message: ''}
            }
        case 'CRITICAL_ERROR':
            return {
                ...state,
                state: sessionStates.CRITICAL_ERROR,
                error: {code: 'CRITICAL_ERROR', message: action.message}
            }
        default:
            return state
    }
}

export default session