import React from 'react';

const InstitutionPickerList = (props) => 
    <div className="dropdown-menu show institustionList">
        {props.institutions.map((element, id) => 
            <InstitutionPickerListElement 
                title={element.name} 
                key={element.slug} 
                highlight={id === props.highlight} 
                onSelect={props.onSelect(element)} 
                onMouseIn={props.onMouseIn(id)}
            />)}
    </div>


const InstitutionPickerListElement = (props) =>
    <button className={"dropdown-item institution-selection-element-display" + (props.highlight ? " institution-selection-element-display-selected" : "")} 
            onClick={props.onSelect}
            onMouseEnter={props.onMouseIn}
        >        
            <span className="card-title">{props.title}</span>
    </button>

export default InstitutionPickerList