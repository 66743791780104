import React from 'react';
import parse from 'html-react-parser';



const LoginCredentialElement = (props) => {
    switch (props.data.type.toLowerCase()) {
        case "text":
            return <div className="form-group">
                <label style={{display: "block"}}>{props.data.name}</label>
                {props.data.description &&
                    <span>{parse(props.data.description)}</span>
                }
                <input type="text" className="form-control" onChange={props.onChange} required={!props.optional} />
            </div>

        case "password":
            return <div className="form-group">
                <label>{props.data.name}</label>
                <input type="password" className="form-control" onChange={props.onChange} required={!props.optional} />
            </div>

        case "captcha":
            return <>
                <div className="form-group">
                    <img src={props.data.src} alt="captcha" />
                </div>
                <div className="form-group">
                    <label>{props.data.name}</label>
                    <input type="text" className="form-control" onChange={props.onChange} required={!props.optional} />
                </div>
            </>

        case "select":
            let optionValues = props.data.values;
            let keys = Object.keys(optionValues);        
            
            return <div className="form-group">
                <label>{props.data.name}</label>
                <select className="form-control" value={props.inputValue} onChange={props.onChange}>
                    {keys.map((key) => {return <option key={key} value={key}>{optionValues[key]}</option>})}
                </select>
            </div>

        case "pinpad":

            let pinPadImages = props.data.pinpads;

            return <div className="form-group">
                <label style={{display: "block"}}>{props.data.name}</label>
                    <input type="hidden" className="form-control" id="dob" onChange={props.onChange}/>
                    <input type="text" className="form-control" id="dob_viewer" required={!props.optional} readOnly/>
                <div className="pin-pad-container">
                {Object.keys(pinPadImages).map(index => (
                    <img key={index}
                        src={pinPadImages[index].src}
                        alt=""
                        data-field-id={index}
                        data-field-type={pinPadImages[index].type}
                        className="pinpad"
                        onClick={props.onPinPadClick}
                    />
                ))}
                </div>
            </div>



        default:
            return null
    }
}

export default LoginCredentialElement