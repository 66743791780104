import React from 'react';

const LoginMfaElement = (props) => {

    let MfaElement = {};
    let htmlAttrs = {};    

    if (props.data.hasOwnProperty('htmlAttrs')) {

        htmlAttrs['style'] = {};                    
    
        Object.keys(props.data.htmlAttrs).forEach(key => {
            
            if (key === 'value') {
                return;
            }

            if (key === 'class') {
                htmlAttrs['className'] = props.data.htmlAttrs[key];
                return;
            }

            if (key === 'onclick') {
                return;
            }

            if (key === 'style'){
                let styles = props.data.htmlAttrs[key].trim().split(';');
                
                styles.forEach(style => {
                    let keyValue = style.trim().split(':')

                    if (keyValue[0].toLowerCase() !== 'width') {
                        htmlAttrs[key][keyValue[0]] = keyValue[1];
                    }                    
                })
                return;
            }

            if (key === 'size') {         
                htmlAttrs['style']['width'] = (props.data.htmlAttrs[key] * 2) + 'em';
                return;
            }

            if (key === 'autocomplete') {         
                htmlAttrs['autoComplete'] = props.data.htmlAttrs[key];
                return;
            }
            
            htmlAttrs[key] = props.data.htmlAttrs[key];
        });
    }

    switch (props.data.type.toLowerCase()) {
        case "set":
            MfaElement = props.data.subElements.map((subElement, index) => <LoginMfaElement key={index} isSet={true} data={subElement} onChange={props.onChange} onSubmit={props.mfaOnSubmit} />);
            break;

        case "header":
            MfaElement = <p className="mfaheader">{props.data.value}</p>
            break;

        case "instructions":            
            MfaElement = <p className="mfainstructions">{props.data.value}</p>
            break;

        case "options":
            const handleOnClick = key => event => {
                props.onChange(props.data.id, key);
                props.onSubmit();
            }

            const MfaButton = (props) => 
                <button type="button" 
                        className="btn btn-primary btn-lg btn-block pl-1 pr-1" 
                        onClick={handleOnClick(props.id)}
                        value={props.id}>
                    {props.value}
                </button>

            MfaElement = Array.isArray(props.data.options)
                ? props.data.options.map((key, index) => <MfaButton key={key} id={index} value={key} />)
                : Object.keys(props.data.options).map(key => <MfaButton key={key} id={key} value={props.data.options[key]} />)
            break;

        case "hidden":
            MfaElement = <input type="hidden" {...htmlAttrs} className="form-control" value={props.data.value}/>              
            break;

        case "input":
            MfaElement = <>            
                {props.isSet || !props.data.name ? null : <label>{props.data.name}</label> }
                <input type="text" {...htmlAttrs} className="form-control" onChange={event => {props.onChange(props.data.id, event.target.value)}}/>
            </>            
            break;

        case "password":
            MfaElement = <>
                {props.isSet || !props.data.name  ? null : <label>{props.data.name}</label> }
                <input type="password" {...htmlAttrs}  className="form-control" onChange={event => {props.onChange(props.data.id, event.target.value)}}/>
            </>            
            break;

        case "image":
            MfaElement = <img src={props.data.src} width={props.data.width} height={props.data.height} alt="" />
            break;

        case "select":
            let optionValues = props.data.values;

            MfaElement = <>
            <label>{props.data.name}</label>
            <select className="form-control" name={props.data.fieldID} onChange={event => {props.onChange(props.data.id, event.target.value)}} value={props.data.inputValue}>
                    {Object.keys(optionValues).map((key) => {return <option value={optionValues[key]}>{key}</option>})}
            </select>
            </>
            break;
        
            case "button" :
            MfaElement = <button {...htmlAttrs} className="btn btn-primary form-control">{props.data.htmlAttrs['value']}</button>
            break;

        case "html" :
            MfaElement = <div className="form-group" dangerouslySetInnerHTML={{__html:props.data.value}}></div>
            break;
        
        default:
            MfaElement = null;
    }

    if (props.isSet) 
        return MfaElement
    else if (props.data.type === "set") {
        return <div className="form-inline form-group">{MfaElement} </div>        
    }
    else {
        return <div className="form-group">{MfaElement}</div>;
    }
}

export default LoginMfaElement