import { combineReducers } from 'redux'
import session from './session'
import institutions from './institutions'
import finishedLogins from './finishedLogins'
import currentLogin from './currentLogin'
import common from './common'
import sessionData from './sessionData'

export default combineReducers({
    common,
    session,
    institutions,
    finishedLogins,
    currentLogin,
    sessionData
})
