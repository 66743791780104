import actions from '../constants/actions'

const institutions = (state = [], action) => {
    switch (action.type) {
        case actions.GET_INSTITUTIONS_START_SUCCESS:
            return {
                ...state,
                byslug: Array.isArray(action.data) ? Object.assign({}, ...action.data.map(element => ({[element.slug]:  element}))) : {}
            }
         
        case actions.PRELOAD_INSTITUTION_SUCCESS:

            let byslug = Object.assign({}, state.byslug);

            byslug[action.data.slug] = action.data;

            return {
                ...state,                
                byslug: byslug                
            }                                 
        default:

            return state
    }
}

export default institutions