import React, { useState } from 'react';

import actions from '../constants/actions'
import parse from 'html-react-parser'
import { replaceMarkup } from './CustomHtmlParser';

const TermsAndConditions = (props) => {
    const [userFullName, setUserFullName] = useState('');

    const onSubmit = (event) => {
        event.preventDefault();
        props.dispatch({type: actions.TERMS_ACCEPTED, userFullName: userFullName});

        // if has winz setting;, automatically dispaly winz login page.
        if(props.settings.requireWinz){
            //if client profile requires winz, then redirect to winz login page;
            props.dispatch({type: actions.CHECK_IF_SUBMIT_WINZ});
            }
    }
    
    return (
        <form onSubmit={onSubmit}>
        {props.theme.text.CustomTermsHeader !== undefined? <h2 className="custom-terms-text">{props.theme.text.CustomTermsHeader}</h2> : null}
        {props.settings.nameRequired ? 
            <p className="selector_CLIENT_NAME">
                <span> {props.theme.text.enterNameTitle !== 'undefined' ? props.theme.text.enterNameTitleTitle : 'Please enter your full name'}</span>
                <input type='text' onChange={e => setUserFullName(e.target.value)} className="form-control" required="required" ></input>
            </p> 
        : null}
        {props.theme.text.enterTncClientInfoText !== undefined && props.theme.text.enterTncClientInfoText !== ' ' ?
            (props.theme.text.enterTncClientInfoMonth !== undefined && props.theme.text.enterTncClientInfoMonth === 'True' ?
            <p className="selector_CLIENT_INFO">{props.theme.text.enterTncClientInfoText.replace('#MONTHS', Math.floor(props.settings.numberOfDays/30)).replace('#CLIENT', props.settings.name)}</p>:
             <p className="selector_CLIENT_INFO">{props.theme.text.enterTncClientInfoText.replace('#DAYS', props.settings.numberOfDays).replace('#CLIENT', props.settings.name)}</p>) :
            <p className="selector_CLIENT_INFO">
                <span className="selector_CLIENT_SETTINGS_DAYS"> {props.settings.numberOfDays}</span> days of {props.theme.text.statementsOrTransactions !== undefined ? props.theme.text.statementsOrTransactions : 'statements'} {props.settings.forceAllAccounts ? <span className="selector_CLIENT_SETTINGS_FORCE_ALL_ACCOUNTS">for all accounts</span> : ''} will be sent to <span className="selector_CLIENT_SETTINGS_NAME">{props.settings.name}</span>.
            </p>
        }
        <p className="selector_TERMS_INFO"><span className='selector_EXTRA_TERMS_INFO'></span>
        {props.theme.text.enterTermsAndConditionText !== undefined && props.theme.text.enterTermsAndConditionText !== ' ' && typeof props.theme.text.enterTermsAndConditionText == 'string' ?
        parse(replaceMarkup(props.theme.text.enterTermsAndConditionText)) :
        parse('To continue please agree to:<br/> If you are in Australia, these <a href="https://bankstatements.com.au/about/terms" target="_blank" rel="noopener noreferrer"><span>End User Terms</span></a> and <a href="https://www.illion.com.au/privacy-policy-risk-marketing-solutions/" target="_blank" rel="noopener noreferrer"><span>Privacy Policy</span></a>; or <br/>If you are in New Zealand, these <a href="https://bankstatements.co.nz/about/terms" target="_blank" rel="noopener noreferrer"><span>End User Terms</span></a> and <a href="https://illion.co.nz/privacy-policy-risk-marketing-solutions/" target="_blank" rel="noopener noreferrer"><span>Privacy Policy</span></a>.')}
        </p>
        <button className="form-control btn btn-primary"  type="submit">{props.theme.text.termsSubmitBtn}</button>
        </form>
    )

}



export default TermsAndConditions;