const selectTargetOrigin = (str) => {
    if (str === '*') {
        return '*';
    }

    const getPatternFromOrigin = (originString) => {
        let suffix = false;
        if(originString.endsWith('*')){
            suffix = true;
            originString = originString.slice(0, -1);
        }

        if(originString.endsWith('/')){
            originString = originString.slice(0, -1);
        }

        originString = originString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        originString = originString.replace('\\*\\.', '([A-Za-z0-9-]*\\.)*');

        if(!originString.match(/:[0-9]{1,4}/)){
            if(suffix){
                originString += '.*';
            }else{
                originString += '(:[0-9]{1,4})?';
            }
        }

        if(!originString.startsWith('http')){
            originString = '((http|https):\\/\\/)?' + originString;
        }

        return new RegExp(`^${originString}$`, 'i');
    }
    
    let referrer = document.referrer;
    if(referrer.endsWith('/')){
        referrer = referrer.slice(0, -1);
    }
    const allowedOrigins = str.split(';').map(origin => origin.trim()).filter(origin => origin !== '');
    const selectedOrigin = allowedOrigins.find(origin => {
        if(origin === referrer || origin === '*') {
            return true;
        }

        if(referrer.match(getPatternFromOrigin(origin))){
            return true;
        }

        return false;
    });

    return selectedOrigin ? referrer : false;
}

export const postMessageSuccess = (event, data = {}) => postMessage(event, data, "success")

export const postMessageError = (event, errorCode, errorMessage, errorInstitute) => postMessage(event, {error_code: errorCode, error_message: errorMessage, data: errorInstitute}, "error")

const postMessage = (event, data = {}, status = "success") => async (dispatch, getState) => {

    const { session } = getState();

    const selectedOrigin = selectTargetOrigin(session.postMessageHost);
    
    if (!session.postMessagesEnabled || !selectedOrigin) {
        return;
    }

    let postMessageData = {
        "source": "BankStatements-SingleCustomerView",
        "event": event,
        "status": status
    }

    // For some error message, we are not setting up institution
    // so removing it from data object
    if(typeof data.data === "undefined")
    {
        delete data.data
    }
    Object.assign(postMessageData, data)

    try {
        window.parent.postMessage(JSON.stringify(postMessageData), selectedOrigin);
    } catch(e) {
        console.error('Could not execute postMessage: ' + e.toString());
    }
}

export default postMessage