const actions = {
    "CRITICAL_ERROR" : "CRITICAL_ERROR", //Whole session error. Session is worthless, won't be able to do anything else

    "GET_THEME_START" : "GET_THEME_START",
    "GET_THEME_SUCCESS" : "GET_THEME_SUCCESS", //Got whitelabel data
    "INJECT_THEME" : "INJECT_THEME",
    "SET_WL" : "SET_WL",

    "GET_INSTITUTIONS_START" : "GET_INSTITUTIONS_START", //Just for logging at the moment. Nothing is being done
    "GET_INSTITUTIONS_START_SUCCESS" : "GET_INSTITUTIONS_START_SUCCESS",

    "PRELOAD_INSTITUTION_START" : "PRELOAD_INSTITUTION_START", //On user selection, if institution requires preload
    "PRELOAD_INSTITUTION_SUCCESS" : "PRELOAD_INSTITUTION_SUCCESS",
    
    "INSTITUTION_SELECTED" : "INSTITUTION_SELECTED",


    "CHECK_IF_SUBMIT_WINZ" : "CHECK_IF_SUBMIT_WINZ",

    "LOGIN_SUBMIT_START" : "LOGIN_SUBMIT_START",
    
    "MFA_SUBMIT_START" : "MFA_SUBMIT_START",

    "ACCOUNTSELECTION_SUBMIT_START" : "ACCOUNTSELECTION_SUBMIT_START",

    "LOGIN_PROCESS_CANCELLED" : "LOGIN_PROCESS_CANCELLED",
    
    "ADD_ANOTHER_INSTITUTION" : "ADD_ANOTHER_INSTITUTION",


    "SUBMIT_ALL_START" : "SUBMIT_ALL_START",
    "SUBMIT_ALL_SUCCESS" : "SUBMIT_ALL_SUCCESS",

    "TERMS_ACCEPTED" : "TERMS_ACCEPTED",



           


    "LOGIN_PROCESS_FINISHED" : "LOGIN_PROCESS_FINISHED",
    
    "LOGIN_PROCESS_MFA" : "LOGIN_PROCESS_MFA",
    "LOGIN_PROCESS_ACCOUNTS_SELECTION" : "LOGIN_PROCESS_ACCOUNTS_SELECTION",

  
    "LOGIN_PROCESS_LOADING" : "LOGIN_PROCESS_LOADING",
    "SESSION_START_NO_CODE_PROVIDED" : "SESSION_START_NO_CODE_PROVIDED",
    "SESSION_START_SUCCESS" : "SESSION_START_SUCCESS",
    "SESSION_START_REQUEST_ERROR" : "SESSION_START_REQUEST_ERROR",
    "INSTITUTION_ERROR" : "INSTITUTION_ERROR"
}

export default actions;