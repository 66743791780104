//Helper function
export const isObject = (obj) => obj === Object(obj)

export const parseUrlParams = (url, allowedParams) => {
    const parts = url.slice(1).split(/&|=/);
    let params = {};

    for (let i = 0; i < parts.length; i += 2) {
        if(allowedParams.includes(parts[i])){
            params[parts[i]] = parts[i + 1];
        }        
    }
    return params;
}

