import React from 'react';
import {institutionSelected} from "../actions";
const CheckIfSubmitWinz = (props) => {
    const onSubmit = (event) => {
        event.preventDefault();
        props.dispatch(institutionSelected('winz', false));

    }
    const onCancel = () => {
        props.dispatch({type: 'LOGIN_PROCESS_CANCELLED'});
    }
    const winzName=props.winzName;
    const winzAddtionalDescription=props.clientName+' has requested that you submit a '+winzName+' statement. If you have online access to MyMSD, we can submit this for you automatically.';
    return (
        // hard code the className so it is the same as login page. otherwise need to add new field to white label;
        <form onSubmit={onSubmit} className='selector_LOGIN_STATE_CREDENTIAL_FORM_form'>
            <div className="form-group"><p>{winzAddtionalDescription}</p></div>
            <div className="form-group">
                <button id="loginSubmitBtn" type="submit"
                        className="form-control btn btn-primary">Submit my WINZ statements</button>
            </div>
            <div className="form-group">
                <button className="form-control btn btn-secondary" onClick={onCancel} type="button">I'm not a WINZ customer</button>
            </div>
        </form>
    )

}



export default CheckIfSubmitWinz;