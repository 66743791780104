import axios from 'axios';
import actions from '../constants/actions'
import { isObject, parseUrlParams } from '../helpers/common'
import {postMessageSuccess, postMessageError} from '../helpers/postMessage'

const requestExtender = (config) => {
    //Adding suffix for backend debugging
    const envSuffix = process.env.REACT_APP_API_URL_SUFFIX;
    if (envSuffix === undefined) {
        return config;
    }
    
    let newConfig = Object.assign({}, config);
    const suffix = newConfig.url.includes('?') ? '&' + (envSuffix).replace('?','') : envSuffix
    newConfig.url = newConfig.url + suffix
    return newConfig;
}

const requestWithoutSession = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT_BASE,
    timeout: process.env.REACT_APP_REQUEST_WITHOUT_SESSION_TIMEOUT,
    validateStatus: function (status) {
        return status <= 500; // Reject only if the status code is greater than 500
    }
}); //For requests to backend WITHOUT session key
requestWithoutSession.interceptors.request.use(requestExtender);

const requestWithSession = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT_BASE,
    timeout: process.env.REACT_APP_REQUEST_WITH_SESSION_TIMEOUT,
    validateStatus: function (status) {
        return status <= 500; // Reject only if the status code is greater than 500
    }
}); //For requests to backend WITH session key. Session key will be populated during startSession() call
requestWithSession.interceptors.request.use(requestExtender);


//Common wrapper for any CRITICAL_ERROR that came from portal API / network malfunction
const criticalApiError = (processName, payload) => async (dispatch) => {

    dispatch({type: actions.CRITICAL_ERROR, message: 'Please contact customer support. '}); //Common action to set up critical error screen
}

//Common wrapper for any error during institution login process. This is not whole session critical error
const institutionApiError = (processName, payload,institution_slug = null) => async (dispatch) => {
    let additionalInfo;
    if (typeof payload == 'string') {
        additionalInfo = checkMessage(payload);
    } else {
        if (payload.response.error != null) {
            let error = payload.response.error;
            additionalInfo = checkMessage(error,institution_slug);
        } else {
            additionalInfo = 'Please contact customer support.';
        }
    }
    dispatch({type: actions.INSTITUTION_ERROR, message: additionalInfo}); //Common action to set up current institution's error screen
}

function checkMessage(payload,institution_slug = null) {
    let additionalInfo = 'Please contact customer support.';
    if(payload.includes('20008')){
        if (payload.includes('Incorrect Date of Birth Format')){// maintaining the same error code
            additionalInfo = 'Please confirm your credentials. Error code (20008)';
        } else if (institution_slug === 'suncorp') {
            additionalInfo = 'Please confirm username/password. If you have a security token or use the Suncorp Secured App you must enter a token code to login. Error code (20008)'
        } else {
            additionalInfo = 'Please confirm username/password. Error code (20008)';
        }        
    }else if (payload.includes('20005')){
        additionalInfo = 'Your online banking account has been locked. You will need to check with your bank for resetting your access. Error code (20005)';
    }else if (payload.includes('20007')){
        additionalInfo = 'Your online banking password has expired. You will need to check with your bank for instructions on resetting your password. Error code (20007)';
    }else if (payload.includes('32008')){
        additionalInfo = 'Your online banking setup is not yet finalised. You will need to check with your bank for instructions on completing setup. Error code (32008)';
    }else if (payload.includes('30004')){
        additionalInfo = 'Your financial institution requires you to perform some manual action before we can export data from your account. Please log in to your financial institution\'s online banking (desktop site) and complete the action requested after you log in. Error code (30004)';
    }else if (payload.includes('10104')){
        additionalInfo = 'The login credentials are not valid. Error code (10104)';
    }else if (payload.includes('10105')){
        additionalInfo = 'Unable to log in to bank account. Credentials may be incorrect. Error code (10105)';
    }else if (payload.includes('20001')){
        additionalInfo = 'Too many login attempts. Error code (20001)';
    }else if (payload.includes('20002')){
        additionalInfo = 'Required credential missing. Error code (20002)';
    }else if (payload.includes('20004')){
        additionalInfo = 'Already logged in. Error code (20004)';
    }else if (payload.includes('30026')){
        additionalInfo = 'This institution is currently experiencing high number of requests being processed,and its unable to process any additional request at this time. We would suggest you try again after 5 minutes.';
    }else if (payload.includes('30027')){
        additionalInfo = 'We tried reprocessing your request after certain time however we were not successful. You could try again after 24 hours.';
    }
    return additionalInfo;
}

//Function starts new session via portal, initiates theme and institution list load on success
//If client code in not found we show 404 screen
//On error we throw CRITICAL_ERROR, as whole session is basically useless
export const startSession = (clientCode, defaultWhitelabel) => async (dispatch) => {
    
    //Obtain reference provided in the URL (like https://dashboard.proviso.som.au/CODE-reference)
    let parts = clientCode.split( '/' );
    let cleanClientCode = '';

    if (parts.length === 3) {
        if (parts[1].toLowerCase() === 'iframe') {
            cleanClientCode = parts[2];
        }        
    } else {
        cleanClientCode = clientCode.replace(new RegExp('/', 'g'), '')
    }

    //If no code provided show 404 screen (it is NOT critical error screen)
    if (cleanClientCode.length === 0) {
        dispatch({ type: actions.SESSION_START_NO_CODE_PROVIDED });
        return
    }
    
    //Start session via portal
    try {
        let { data, status } = await requestWithoutSession.post('init/' + cleanClientCode);

        let sessionToken = undefined
        if ('sessionToken' in data) {
            sessionToken = data.sessionToken
            requestWithSession.defaults.headers.common['X-SESSION-TOKEN'] = sessionToken; //Put it in global request mechanism right away
            dispatch({type: actions.SESSION_START_SUCCESS, referralCode: cleanClientCode, ...data});

            let receivedWlName = data.whitelabel.replace(new RegExp('wl:', 'g'), '');
            let whitelabel = receivedWlName.length > 0 ? receivedWlName : defaultWhitelabel;

            //if no white label received , set it to illion
            if(whitelabel.trim() === ''){
                whitelabel = 'illion';
            }

            let wlStyles;
            let wlTheme;
            if ('whitelabelData' in data) {
                wlStyles = data.whitelabelData.styles;
                wlTheme = data.whitelabelData.theme;
                dispatch({type: actions.INJECT_THEME, styles: wlStyles, theme: wlTheme})
            } else {
                dispatch(loadTheme(whitelabel));
            }

            dispatch(getInstitutions());

        } else {
            dispatch(criticalApiError('START_SESSION', {status: status, response: data}));
        }

    } catch(error) {

        if (error.response) { //We got some answer from API
            dispatch(criticalApiError('START_SESSION', ' '));
        } else if (error.message) {
            dispatch(criticalApiError('START_SESSION', ' '));
        } else {
            dispatch(criticalApiError('START_SESSION', ' '));
        }

    }
}

//Function obtains whitelabel data (json file stored separately) and then sets SESSION as successfully started
//On error we throw CRITICAL_ERROR, as whole session is basically useless
export const loadTheme = (whitelabel) => async (dispatch) => {

    dispatch({type: actions.GET_THEME_START})

    try {

        let loc = window.location;
        let targetUrl = loc.protocol + '//' + loc.hostname + (loc.port ? ':' + loc.port: '') + '/whitelabel/' + whitelabel + '/wl.json';
        let { data, status } = await requestWithoutSession.get(targetUrl);

        switch (status) {
            case 200:
                dispatch({type: actions.SET_WL, whitelabel: whitelabel});
                setTimeout(() => {
                    dispatch({type: actions.GET_THEME_SUCCESS, theme: data})
                  }, 100)
                
                break
            default:
                dispatch(criticalApiError('LOAD_THEME', {url: targetUrl, status: status}));
        }
        
    } catch(error) {

        if (error.response) { //We got some answer from API
            dispatch(criticalApiError('LOAD_THEME', ' '));
        } else if (error.message) {
            dispatch(criticalApiError('LOAD_THEME', ' '));
        } else {
            dispatch(criticalApiError('LOAD_THEME', ' '));
        }

    }
}

//Function obtains full list of institutions from portal
//On error we throw CRITICAL_ERROR, as whole session is basically useless
export const getInstitutions = () => async (dispatch) => {

    dispatch({type: actions.GET_INSTITUTIONS_START})

    try {
        let { data, status } = await requestWithSession.get('/institutions');

        switch (status) {
            case 200:
                dispatch({type: actions.GET_INSTITUTIONS_START_SUCCESS, data: data.institutions})
                break
            default:
                dispatch(criticalApiError('GET_INSTITUTIONS', {status: status, response: data}));
        }
        
    } catch(error) {

        if (error.response) { //We got some answer from API
            dispatch(criticalApiError('GET_INSTITUTIONS', ' '));
        } else if (error.message) {
            dispatch(criticalApiError('GET_INSTITUTIONS', ' '));
        } else {
            dispatch(criticalApiError('GET_INSTITUTIONS', ' '));
        }

    }
}

//Function performs institution preload via portal (if required) and continues to normal login
//On any error (network or received from portal) we throw non-critical 
//institutionApiError that affects only current login process, not whole session
export const institutionSelected = (slug, requires_preload)  => async (dispatch) => {

    if (requires_preload === "1") {

        dispatch({type: actions.PRELOAD_INSTITUTION_START, slug: slug});

        try {
            let { data, status } = await requestWithSession.get('/preload/' + slug);
    
            switch (status) {
                case 200:
                    dispatch({type: actions.PRELOAD_INSTITUTION_SUCCESS, data: data.institution}); //Note: Event triggers two reducers
                    dispatch(postMessageSuccess("bank_selected", {data: slug}));
                    break
                default:
                    dispatch(institutionApiError('PRELOAD', {status: status, response: data}));
                    dispatch(postMessageError("bank_selected", data.errorCode, data.error, slug));
            }
            
        } catch(error) {
            if (error.response) { //We got some answer from API
                dispatch(institutionApiError('PRELOAD', error.response.data));
            } else if (error.message) {
                dispatch(institutionApiError('PRELOAD', error.message));
            } else {
                dispatch(institutionApiError('PRELOAD', error.toString()));
            }
            dispatch(postMessageError("bank_selected", 0, 'N/A'));
        }
    } else {
        dispatch({type: actions.INSTITUTION_SELECTED, slug: slug});
        dispatch(postMessageSuccess("bank_selected", {data: slug}));
    }    
}

//Function performs login to institution via portal
//On any error (network or received from portal) we throw non-critical 
//institutionApiError that affects only current login process, not whole session
export const institutionLogin = (institution, credentials) => async (dispatch, getState) => {
    
    dispatch({type: actions.LOGIN_SUBMIT_START, credentials: credentials})
    
    try {
        const postData = {
            'credentials' : {...credentials, institution: institution.slug}, 
            'name': getState().sessionData.userFullName,
            'urlParams' : parseUrlParams(window.location.search, ['ip'])
        };
        
        let { data, status } = await requestWithSession.post('/login', postData);

        switch (status) {
            case 200:
                if (isObject(data) && data.hasOwnProperty('accountsSelectionSubmitted')) {
                    dispatch({ type: actions.LOGIN_PROCESS_FINISHED, data: {slug: institution.slug, name: institution.name}}) 
                    dispatch(postMessageSuccess("submission_complete", {data: institution.slug}));
                }else if (isObject(data) && data.hasOwnProperty('accounts')) {
                    dispatch({type: actions.LOGIN_PROCESS_ACCOUNTS_SELECTION, data: {accounts: data.accounts, slug: institution.slug, name: institution.name}});
                    dispatch(postMessageSuccess("login_clicked", {data: institution.slug}));
                } else if (isObject(data) && data.hasOwnProperty('mfa')) {
                    dispatch({type: actions.LOGIN_PROCESS_MFA, data: {slug: institution.slug, mfa : data.mfa}});
                    dispatch(postMessageSuccess("login_clicked", {data: institution.slug}));
                } else {
                    dispatch(institutionApiError('LOGIN', {reason: "UNHANDLED_DATA", response: data},institution.slug));
                    dispatch(postMessageError("login_clicked", 0, 'N/A'));
                }                
                break
            default:
                dispatch(institutionApiError('LOGIN', {status: status, response: data},institution.slug));
                dispatch(postMessageError("login_clicked", data.errorCode, data.error, institution.slug));
        }
        
    } catch(error) {
        if (error.response) { //We got some answer from API
            dispatch(institutionApiError('LOGIN', error.response.data));
        } else if (error.message) {
            dispatch(institutionApiError('LOGIN', error.message));
        } else {
            dispatch(institutionApiError('LOGIN', error.toString()));
        }
        dispatch(postMessageError("login_clicked", 0, 'N/A'));
    }
}

//Function performs MFA data sending to institution via portal
//On any error (network or received from portal) we throw non-critical 
//institutionApiError that affects only current login process, not whole session
export const processMFA = (institution, mfaFields) => async (dispatch) => {
    
    dispatch({ type: actions.MFA_SUBMIT_START})

    try {


        //add institution to support auto account selection
        if(isObject(mfaFields)){
            mfaFields.institution = institution.slug;
        }

        const postData = mfaFields;
        let { data, status } = await requestWithSession.post('/mfa', postData);
        switch (status) {
            case 200:
                if (isObject(data) && data.hasOwnProperty('accountsSelectionSubmitted')) {
                    dispatch({ type: actions.LOGIN_PROCESS_FINISHED, data: {slug: institution.slug, name: institution.name}}) 
                    dispatch(postMessageSuccess("submission_complete", {data: institution.slug}));
                }else if (isObject(data) && data.hasOwnProperty('accounts')) {
                    if(institution.slug==='winz'){
                        // automatically send get account data request.
                        let winzAccount=[0];
                        dispatch(submitSelectedAccounts (institution, winzAccount, ''));
                        return;
                    }
                    dispatch({ type: actions.LOGIN_PROCESS_ACCOUNTS_SELECTION, data: {accounts: data.accounts, slug: institution.slug, name: institution.name} })
                } else if (isObject(data) && data.hasOwnProperty('mfa')) {
                    dispatch({type: actions.LOGIN_PROCESS_MFA, data: {slug: institution.slug, mfa : data.mfa}})
                } else {
                    dispatch(institutionApiError('MFA', {reason: "UNHANDLED_DATA", response: data}));
                }
                break
            default:
                dispatch(institutionApiError('MFA', {status: status, response: data}));
        }
        
    } catch(error) {
        if (error.response) { //We got some answer from API
            dispatch(institutionApiError('MFA', error.response.data));
        } else if (error.message) {
            dispatch(institutionApiError('MFA', error.message));
        } else {
            dispatch(institutionApiError('MFA', error.toString()));
        }  
    }
}

//Function performs sending of selected accounts to institution via portal
//On any error (network or received from portal) we throw non-critical 
//institutionApiError that affects only current login process, not whole session
export const submitSelectedAccounts = (institution, selectedAccounts, password) => async (dispatch, getState) => {

    dispatch({ type: actions.ACCOUNTSELECTION_SUBMIT_START})
    dispatch(postMessageSuccess("accounts_selected", {data: institution.slug}));

    try {    
        let postData = {
            accounts: {[institution.slug]: selectedAccounts},
            password : password
        };

        if(getState().sessionData.clientSettings.forceNoAccounts) {
            postData['forceNoAccounts'] = true;
        }

        let { data, status } = await requestWithSession.post('/selectAccounts', postData);
        switch (status) {
            case 200:
                    dispatch({ type: actions.LOGIN_PROCESS_FINISHED, data: {slug: institution.slug, name: institution.name}}) //Clear the "current login process" view
                    dispatch(postMessageSuccess("submission_complete", {data: institution.slug}));
                break
            default:
                dispatch(institutionApiError('ACCOUNTSELECTION', {status: status, response: data}));
                dispatch(postMessageError("submission_complete", data.errorCode, data.error, institution.slug));
        }
        
    } catch(error) {
        if (error.response) { //We got some answer from API
            dispatch(institutionApiError('ACCOUNTSELECTION', error.response.data));
        } else if (error.message) {
            dispatch(institutionApiError('ACCOUNTSELECTION', error.message));
        } else {
            dispatch(institutionApiError('ACCOUNTSELECTION', error.toString()));
        }
        dispatch(postMessageError("submission_complete", 0, 'N/A'));
    }
}

//Function finalises whole session by sending signal to portal
//On error we throw CRITICAL_ERROR, as whole session is basically useless
export const submitAll = () => async (dispatch) => {
    
    dispatch({type: actions.SUBMIT_ALL_START});

    try {

        let { data, status } = await requestWithSession.post('/finished');

        switch (status) {
            case 200:
                dispatch({type: actions.SUBMIT_ALL_SUCCESS});
                dispatch(postMessageSuccess("submit_all"));
                break
            default:
                dispatch(criticalApiError('SUBMIT_ALL', {status: status, response: data}));
                dispatch(postMessageError("submit_all", data.errorCode, data.error));
        }
        
    } catch(error) {
        if (error.response) { //We got some answer from API
            dispatch(criticalApiError('SUBMIT_ALL', ' '));
        } else if (error.message) {
            dispatch(criticalApiError('SUBMIT_ALL', '  '));
        } else {
            dispatch(criticalApiError('SUBMIT_ALL', ' '));
        }
        dispatch(postMessageError("submit_all", 0, 'N/A'));
    }    
}

export const sendLogEntry = async (data) => {
    try {
        //console.warn('MIDDLEWARE: ', data)
        //await requestWithoutSession.post('/frontendlog', data);
    } catch(error) {}
}