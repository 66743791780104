import React from 'react';

// Login Account Selection Element
const LoginASElement = (props) => {
    const elementId = "LoginASElement-checkbox-" + props.index;

    return <div className="form-group">
        <div className="input-group input-group-lg">
            <div className="input-group-prepend account-checkbox">
                <div className="input-group-text">
                    <input id={elementId} type="checkbox" onChange={props.onChange} value={props.index} checked={props.checked} />
                    <label htmlFor={elementId}></label>
                </div>
            </div>
            <div className="input-group-append account-info">
                <div className="input-group-text">
                    <div className="row">
                        <div className="col">{props.data.name}</div>
                        <div className="col"><AccountInfoElement {...props.data} /></div>
                    </div>
                    <AccountBalanceElement {...props.data} />
                </div>
            </div>
        </div>
    </div>;
}

const AccountInfoElement = (props) => {
    let accInfo = {};

    // Account Number and BSB for show account selection screen
    // Only add BSB if exists and isn't empty
    // eg: [Acc: 12345678 BSB: 121-323]
    if (props.hasOwnProperty('accountNumber') && props.accountNumber !== null && props.accountNumber.length > 0){
        accInfo['Acc'] = props.accountNumber.toString().trim();
    }
    if (props.hasOwnProperty('bsb') && props.bsb !== null && props.bsb.length > 0){
        accInfo['BSB'] = props.bsb.toString().trim();
    }

    const accInfoString = Object.keys(accInfo).map(key => `${key}: ${accInfo[key]}`).join(' ');

    return  <small>[{accInfoString}]</small>;
}

const AccountBalanceElement = (props) => {
    let balanceNumber = 0.00;

    // Format the account balance
    if (props.hasOwnProperty('balance') && props.balance !== null){
        balanceNumber = parseFloat(props.balance)
    }

    let balanceString = (balanceNumber >= 0 ? '' : '-') + '$' + Math.abs(balanceNumber).toFixed(2).toLocaleString();

    return <div className="balance">{balanceString}</div>;
}


export default LoginASElement