import { shape, exact, any, object, string, bool, number, oneOf, arrayOf } from 'prop-types';
import { sessionStates, loginStates } from "./constants/states";

const initialStoreValues = 
{
    common: { //Dev & other stuff. Probably will be removed
        themeReady: false,
        theme : {
            header : [],  
            info:[],          
            footer : [],
            completedScreen : [],
            classes : {},
            text : {}
        },
        style: '',
        whitelabel : 'illion' //default whitelabel
    },
    session: { //Everything about initializing the session, storing keys, etc
        state: sessionStates.SESSION_NOT_INIT,
        error: {code: '', message: ''},
        sessionToken: '',
        referralCode: '', //client referral code
        postMessagesEnabled: false,
        postMessageHost: '*',
        loggingEnabled: false,
        redirectUrl: ''
    },
    sessionData: {
        clientSettings: {
            name: '',
            forceAllAccounts: false,
            forceSingleAccount: false,
            forceNoAccounts: false,
            requiresEstatements: false,
            requestAllAccounts: false,
            numberOfDays: 90,
            nameRequired : false,
            redirectUrl: ''
        },
        acceptedTerms: false,
        userFullName: ''
    },
    institutions: { //List of institutions obtained from API
        byslug: {}
    },
    finishedLogins: {
        list: []
    },
    currentLogin: {
        state: loginStates.LOGIN_STATE_INSTITUTIONS_PICKER,
        slug: '',
        mfa : null,
        accounts: [],
        error: {message: ''}
    }
}

export const themeType = object

export const commonType = exact({
    themeReady: bool.isRequired,
    theme: themeType.isRequired,
    style: string.isRequired,
    whitelabel: string.isRequired
})

export const sessionType = exact({
    state: oneOf(Object.values(sessionStates)).isRequired,
    error: shape({
        code: string,
        message: string
    }),
    sessionToken: string.isRequired,
    referralCode: string.isRequired,
    postMessagesEnabled: bool.isRequired,
    postMessageHost: string.isRequired,
    loggingEnabled: bool.isRequired,
    redirectUrl: string.isRequired
})

export const institutionType = exact({
    slug: string.isRequired, 
    name: string.isRequired,
    status: string.isRequired,
    credentials: arrayOf(shape).isRequired,
    searchVal: string.isRequired,
    export_with_password: string.isRequired,
    requires_preload: string.isRequired,
    estatements_supported: string.isRequired,
    hide_merged_estatement_privacy_note: string.isRequired
})

export const institutionsType = exact({
    byslug: arrayOf(institutionType).isRequired
})

export const finishedLoginsType = exact({
    list: arrayOf(exact({
        slug: string.isRequired,
        name: string.isRequired
    })).isRequired
})

export const currentLoginType = exact({
    state: oneOf(Object.values(loginStates)).isRequired,
    slug: string.isRequired,
    mfa : any,
    accounts: arrayOf(shape),
    error: shape({
        message: string.isRequired
    })
})

export const sessionDataType = exact({
    clientSettings: exact({
        name: string.isRequired,
        forceAllAccounts: bool.isRequired,
        forceSingleAccount: bool.isRequired,
        forceNoAccounts: bool.isRequired,
        requiresEstatements: bool.isRequired,
        requestAllAccounts: bool.isRequired,
        nameRequired: bool.isRequired,
        numberOfDays: number.isRequired,
        redirectUrl: string.isRequired,
        requireWinz: bool.isRequired
    }).isRequired,
    acceptedTerms: bool.isRequired,
    userFullName: string.isRequired
})

export default initialStoreValues