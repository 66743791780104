import actions from '../constants/actions'

const sessionData = (state = [], action) => {
    switch (action.type) {
        case 'SESSION_START_SUCCESS':
            return {
                ...state,
                clientSettings: {
                    name: action.name,
                    forceAllAccounts: action.forceAllAccounts,
                    forceSingleAccount: action.forceSingleAccount,
                    forceNoAccounts: action.forceNoAccounts,
                    requiresEstatements: action.requiresEstatements,
                    requestAllAccounts: action.requestAllAccounts,
                    numberOfDays: action.numberOfDays,
                    nameRequired: action.iFrameAskForName,
                    redirectUrl: action.redirectUrl,
                    requireWinz:action.requireWinz
                }
            }        
        case actions.TERMS_ACCEPTED:
            return {
                ...state,
                acceptedTerms: true,
                userFullName: action.userFullName
            }
        default:
            return state
        }
}

export default sessionData