import React from 'react';
import { connect } from 'react-redux'
import { institutionSelected } from '../actions'

import InstitutionPickerList from './InstitutionPickerList'

const KEY_CODE_ENTER = 13;
const KEY_CODE_UP = 38;
const KEY_CODE_DOWN = 40;

class InstitutionPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //Local stuff, no need to propagate to global store
            searchValue: '', 
            institutionsToShow: [],
            highlightedInstitutionId: null
        };

        this.mainInputRef = React.createRef();
    }

 
    componentDidMount() {
        if (Object.keys(this.props.institutions).length > 0) {
            this.mainInputRef.current.focus();
        }
    }

    componentDidUpdate() {
        if (Object.keys(this.props.institutions).length > 0 && this.state.searchValue !== 'ALL') {
            this.mainInputRef.current.focus();
        }        
    }


    handleChange = event => {
        const { value } = event.target;
        this.setState({
            searchValue: value,
            institutionsToShow: this.getFilteredInstitutions(value),
            highlightedInstitutionId: null
        });
    };

    handleKeyDown = event => {
        if (event.keyCode === KEY_CODE_DOWN || event.keyCode === KEY_CODE_UP) {
            event.preventDefault();

            let nextId = this.state.highlightedInstitutionId
            if (event.keyCode === KEY_CODE_DOWN) {
                if (this.state.highlightedInstitutionId === null && this.state.institutionsToShow.length > 0) {
                    nextId = 0;
                } else {
                    nextId = this.state.highlightedInstitutionId < this.state.institutionsToShow.length - 1 ? this.state.highlightedInstitutionId + 1 : this.state.highlightedInstitutionId;
                }
            } else {
                if (this.state.highlightedInstitutionId === null && this.state.institutionsToShow.length > 0) {
                    nextId = this.state.institutionsToShow.length - 1;
                } else {
                    nextId = this.state.highlightedInstitutionId <= 0 ? 0 : this.state.highlightedInstitutionId - 1;
                }
            }

            this.setState({highlightedInstitutionId: nextId});

        } else if (event.keyCode === KEY_CODE_ENTER) {
            event.preventDefault();

            if (typeof this.state.institutionsToShow[this.state.highlightedInstitutionId] !== 'undefined') {
                const institutionData = this.state.institutionsToShow[this.state.highlightedInstitutionId];
                this.props.dispatch(institutionSelected(institutionData.slug, institutionData.requires_preload));
            }
        }

        
    }

    institutionOnMouseEnter = id => event => {
        this.setState({highlightedInstitutionId: id});
    }

    getFilteredInstitutions = searchValue => {

        const adjustedSearchValue = searchValue.replace(/\s|_/g, '').toLowerCase();

        if (adjustedSearchValue.length <= 2) {
            return []
        }

        if (adjustedSearchValue === 'all') {
            return Object.values(this.props.institutions).filter(one=>one.slug !=='winz');
        }
        // remove winz from search result;
        let institutionsWinzRemoved=Object.values(this.props.institutions).filter(one=>one.slug !=='winz');
        let tmp = Object.values(institutionsWinzRemoved).filter(element => element.searchVal.indexOf(adjustedSearchValue) >= 0);
        return tmp.sort((a,b) => {return a.searchVal.indexOf(adjustedSearchValue) > b.searchVal.indexOf(adjustedSearchValue) ? 1 : -1})
    }

    dispatchInstitutionSelected = (institutionData) => (event) => this.props.dispatch(institutionSelected(institutionData.slug, institutionData.requires_preload)) 


    render() {
        return (
            <>                
            {this.props.pickerOnly ? null : <div className="form-group"><h2>{this.props.theme.text.institutionPickerTitle}</h2></div>}
            
            <div className="form-group">
                <div className="input-group">
                    <input type="text" 
                        className="form-control" 
                        value={this.state.searchValue} 
                        onChange={this.handleChange} 
                        placeholder={this.props.theme.text.institutionPickerSearchPlaceholder} 
                        disabled={Object.keys(this.props.institutions).length === 0} 
                        onKeyDown={this.handleKeyDown}
                        ref={this.mainInputRef}
                        />
                    <div className="input-group-append">
                        <button type="button" 
                            className="btn btn-outline-secondary dropdown-toggle"
                            onClick={ event => { this.state.searchValue.toLowerCase() === 'all' ?
                                this.setState({
                                    searchValue: '', 
                                    institutionsToShow: this.getFilteredInstitutions(''),
                                    highlightedInstitutionId: null
                                })
                                :
                                this.setState({
                                    searchValue: 'ALL',
                                    institutionsToShow: this.getFilteredInstitutions('ALL'),
                                    highlightedInstitutionId: null                                
                                })
                            }} 
                            disabled={Object.keys(this.props.institutions).length === 0}>
                        </button>
                    </div>
                </div>

                {this.state.institutionsToShow.length > 0 ? 
                    <InstitutionPickerList 
                        institutions={this.state.institutionsToShow} 
                        highlight={this.state.highlightedInstitutionId} 
                        onSelect={this.dispatchInstitutionSelected} 
                        onMouseIn={this.institutionOnMouseEnter}
                    />
                    : null}
            </div>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        institutions: state.institutions.byslug,
        theme: state.common.theme
    }
}

export default connect(mapStateToProps)(InstitutionPicker)
