import React from 'react';
import { connect } from 'react-redux'
import { finishedLoginsType } from '../initialStoreValues'

import InstitutionPicker from './InstitutionPicker'
import { submitAll } from '../actions'
import actions from '../constants/actions' 

class FinishedInstitutionLogins extends React.Component {

    render() {
        if (this.props.finishedLogins.list.length > 0) {
            const containsOnlyWinz  = this.props.finishedLogins.list.every(item => item.slug === 'winz');
            // Set the addAnotherInstitutionBtn text based on the condition
            const addAnotherInstitutionBtnText = containsOnlyWinz ? "Add Bank" : this.props.theme.text.addAnotherInstitutionBtn;
            return <>
                <div className="form-group">
                    <h2 className="d-flex justify-content-center">{this.props.finishedLogins.list.length > 1 ? this.props.theme.text.completedInstitutionsTitleMultipleInstitutions : this.props.theme.text.completedInstitutionsTitleOneInstitution}</h2>
                </div>

                <div className="form-group selector_FINISHED_LOGINS">
                    {this.props.finishedLogins.list.map((element, i) => <FinishedLoginElement data={element} key={i} />)}
                </div>
                
                {this.props.withPicker !== true ? <AddAnotherBank onClick={event => {this.props.dispatch({type: actions.ADD_ANOTHER_INSTITUTION})}} text={addAnotherInstitutionBtnText} /> : null}
                {this.props.withPicker === true ? <InstitutionPicker pickerOnly={true}/> : null}
                
                <SubmitAll onClick={event => {this.props.dispatch(submitAll())}} text={this.props.theme.text.submitAllBtn} />       
            </>
        } else {
            return <InstitutionPicker pickerOnly={false}/>
        }
    }

}

const FinishedLoginElement = (props) => 
    <div className="form-group selector_FINISHED_LOGIN_ELEMENT">
        <div className="alert alert-warning" role="alert">{props.data.name} <span className="icon-check"></span></div>
    </div>
 
const AddAnotherBank = (props) =>
    <div className="form-group">
        <button className="form-control btn btn-primary" onClick={props.onClick} type="button">{props.text}</button>
    </div>

const SubmitAll = (props) =>
    <div className="form-group">
        <button className="form-control btn btn-secondary" onClick={props.onClick} type="button">{props.text}</button>
    </div> 

FinishedInstitutionLogins.propTypes = {
    finishedLogins: finishedLoginsType.isRequired
}

const mapStateToProps = (state) => {
    return {
        theme: state.common.theme,
        finishedLogins: state.finishedLogins
    }
}

export default connect(mapStateToProps)(FinishedInstitutionLogins)