import actions from '../constants/actions'

const mergeTheme = (source, target) => {
    
    source.header = target.header
    source.info = target.info
    source.footer = target.footer    
    source.favicon = typeof target.favicon !== "undefined" && target.favicon !== null ? target.favicon : "/favicon.ico";
    source.completedScreen = target.completedScreen

    for (let key of Object.keys(target.classes)) {
        source.classes[key] =  target.classes[key]
    }

    for (let key of Object.keys(target.text)) {
        source.text[key] =  target.text[key]
    }

    return source
}

const common = (state = [], action) => {
    switch (action.type) {
        case actions.GET_THEME_SUCCESS:
            return {
                ...state,
                themeReady: true,
                theme: mergeTheme(state.theme, action.theme)
            }
        
        case actions.INJECT_THEME:
            return {
                ...state,
                themeReady: true,
                theme: mergeTheme(state.theme, action.theme),
                whitelabel: '',
                style: action.styles
            }
        case actions.SET_WL:
            return {
                ...state,
                whitelabel: action.whitelabel
            }
            
            
        default:
            return state
    }
}

export default common