export const loginStates = {
    "LOGIN_STATE_ERROR" : "LOGIN_STATE_ERROR",
    "LOGIN_STATE_INSTITUTIONS_PICKER" : "LOGIN_STATE_INSTITUTIONS_PICKER",
    "LOGIN_STATE_CREDENTIAL_FORM" : "LOGIN_STATE_CREDENTIAL_FORM",
    "LOGIN_STATE_LOADING" : "LOGIN_STATE_LOADING",
    "LOGIN_STATE_FINISHED" : "LOGIN_STATE_FINISHED",
    "LOGIN_STATE_MFA_FORM" : "LOGIN_STATE_MFA_FORM",
    "LOGIN_STATE_SUBMIT_ALL_LOADING" : "LOGIN_STATE_SUBMIT_ALL_LOADING",
    "LOGIN_STATE_ALL_FINISHED" : "LOGIN_STATE_ALL_FINISHED",
    "LOGIN_STATE_ACCOUNTS_SELECTION" : "LOGIN_STATE_ACCOUNTS_SELECTION",
    "LOGIN_STATE_CHECK_IF_SUBMIT_WINZ" : "LOGIN_STATE_CHECK_IF_SUBMIT_WINZ"
}

export const sessionStates = {
    "CRITICAL_ERROR": "CRITICAL_ERROR",
    "SESSION_START_ERROR" : "SESSION_START_ERROR",
    "SESSION_NOT_INIT" : "SESSION_NOT_INIT",    
    "SESSION_STARTED" : "SESSION_STARTED"
}